import { AUTH_USER, AUTH_ERROR } from "../actions/types";

const INITIAL_STATE = {
  authenticated: "",
  errorMessage: "",
  authType: null,
  profile: null,
  timezone: [],
  balance:null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authenticated: action.payload };
    case AUTH_ERROR:
      return { ...state, errorMessage: action.payload };
    case "AUTH_TYPES":
      return { ...state, authType: action.payload };
    case "PROFILE":
      return { ...state, profile: action.payload };
    case "BALANCE":
      return { ...state, balance: action.payload };
    case "TIMEZONES":
      return { ...state, timezone: action.payload };

    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        authenticated: {
          ...state.authenticated,
          accessToken: action.accessToken,
        },
      };
    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        authenticated: {
          ...state.authenticated,
          refreshToken: action.refreshToken,
        },
      };
    // case "SET_SELECTED_TENANT":
    //   return {
    //     ...state,
    //     selectedTenant: action.payload,
    //   };
    // case SET_TENANT_ROLE_ID:
    //   return {
    //     ...state,
    //     tenantRoleId: action.payload,
    //   };
    default:
      return state;
  }
}
