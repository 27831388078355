 
const initialState = {
    livedetails:[],
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case "LIVE DETAILS":{
            return {
              ...state,
              livedetails: action.payload,
            };
          }
        default:
          return state;
      }
    };
 