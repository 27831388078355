import React, { createContext, useState } from "react";
import logowhiteline from "../../assets/images/logo-white.svg";
import Favicon from "../../assets/images/favicon.ico";
import firstimg from "../../assets/images/login_bg.png";

const CompanyContext = createContext();

const CompanyProvider = ({ children }) => {
  const [companyDetails, setCompanyDetails] = useState({
    title: localStorage.getItem("title") || "Ringopus",
    logo: localStorage.getItem("logo") || logowhiteline,
    favicon: localStorage.getItem("favicon") || Favicon,
    bgimage: localStorage.getItem("bgimage") || firstimg,
  });

  const updateCompanyDetails = (newDetails) => {
    setCompanyDetails(newDetails);
    localStorage.setItem("title", newDetails.title);
    localStorage.setItem("logo", newDetails.logo);
    localStorage.setItem("favicon", newDetails.favicon);
    localStorage.setItem("bgimage", newDetails.bgimage);
  };

  return (
    <CompanyContext.Provider value={{ companyDetails, updateCompanyDetails }}>
      {children}
    </CompanyContext.Provider>
  );
};

export { CompanyProvider, CompanyContext };
