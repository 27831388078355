import React from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
const PageLoading = (props) => {
  const { loading } = props;
  if (!loading) return null;
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        margin: "auto",
        top: "0",
        height:
        document.getElementById("root").clientHeight > 600
            ? `${100 + document.getElementById("root").clientHeight}px`
            : "100%",
        background: "#333",
        width: "100%",
        textAlign: "center",
        zIndex: 9999,
        opacity: "0.3",
        borderRadius: "6px",
      }}
    >
      <Spin size="large" loading={`${loading}`}  />
    </div>
  );
};
const mapStateToProps = ({ loader }) => {
  return { loading: loader.loading };
};
export default connect(mapStateToProps)(PageLoading);
