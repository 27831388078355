/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    audiolists:[],
    audiolistsort:[],
    singleaudio:{}, 
    musiclists:[],
    musiclistsort:[],
    singlemusic:{},
    voicemails:[],
    voicemailsort:[],
    singlevoicemail:{},
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {

      case "AUDIO_LISTS" : {
        return {
          ...state,
          audiolists: action.payload,
        };
      }
      case "AUDIO_LIST_SORT": {
        return { 
            ...state, 
            audiolistsort: action.payload 
        };
      }
      case "AUDIO_SINGLE": {
        return { 
            ...state, 
            singleaudio: action.payload 
        };
      }
    
      case "DELETE_AUDIO": {
        return {
          ...state,
          audiolistsort: [...state.audiolistsort.results?.filter(x => x.id !== action.payload)]
        };
      }
      case "MUSIC_LISTS":{
        return {
          ...state,
         musiclists: action.payload,
        };
      }
      case "MUSIC_LIST_SORT": {
        return {
          ...state,
          musiclistsort: action.payload,
        };
      }
      case "MUSIC_SINGLE": {
        return { 
            ...state, 
            singlemusic: action.payload 
        };
      }
      case "DELETE_MUSIC": {
        return {
          ...state,
          musiclistsort: [
            ...state.musiclistsort.results?.filter((x) => x.id !== action.payload),
          ],
        };
      }
      case "VOICEMAIL_LISTS":{
        return {
          ...state,
         voicemails: action.payload,
        };
      }
      case "VOICEMAIL_LIST_SORT": {
        return {
          ...state,
          voicemailsort: action.payload,
        };
      }
      case "VOICEMAIL_SINGLE": {
        return { 
            ...state, 
            singlevoicemail: action.payload 
        };
      }
      case "DELETE_VOICEMAIL": {
        return {
          ...state,
          voicemailsort: [
            ...state.voicemailsort.results?.filter((x) => x.id !== action.payload),
          ],
        };
      }
      default: {
        return state;
      }
    }
  };
  